import React from "react"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import HomePage from "../components/HomePage"

const IndexPage = () => {
  const menuItems = [
    { name: "about", scrollLink: true },
    { name: "speakers", scrollLink: true },
    { name: "venue", scrollLink: true },
    { name: "tickets", scrollLink: true },
    { name: "partners", scrollLink: true },
  ]
  return (
    <>
      <SEO title="Home" />
      <Navbar items={menuItems} topOffset={100} bottomOffset={150} />
      <HomePage />
      <Footer items={menuItems} />
    </>
  )
}

export default IndexPage
