import React from "react"
import styled from "styled-components"

import Landing from "./Landing"
import About from "./About"
import Episode from "./Episode"

import Image from "../../elements/image"

import media from "../../../styles/media"

import texture_two from "./images/texture.png"

const Container = styled.div`
  background-color: rgba(0, 93, 107, 0.99);
  position: relative;
  overflow: hidden;
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    img {
      object-fit: contain !important;
    }
    &.ryan-holiday {
      transform: translateX(-10vw);
      img {
        object-position: top left !important;
      }
    }
    &.black-coffee {
      transform: translateX(10vw);
      img {
        object-position: top right !important;
      }
    }
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 93, 107, 0.99);
    mix-blend-mode: lighten;
  }
  ${media.tablet`
    .gatsby-image-wrapper {
      position: absolute !important;
      top: 0;
      height: 85% !important;
      img {
        object-fit: contain !important;
      }
      &.ryan-holiday {
        transform: translateX(0);
        img {
          object-position: top left !important;
        }
      }
      &.black-coffee {
        transform: translateX(0);
        img {
          object-position: top right !important;
        }
      }
    }
  `}
`

const EpisodeExtended = styled(Episode)`
  position: absolute;
  z-index: 1;
  top: auto;
  bottom: 30px;
  left: 5px;
  ${media.tablet`
    top: 30px;
    bottom: auto;
    left: 30px;
  `}
`

const Backdrop = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 96vw;
  height: calc(100% - 25vh);
  background-color: rgba(255, 0, 0, 0.35);
  ${media.tablet`
    display: block;
    height: calc(100% - 65vh);
  `}
`

const Texture = styled.div`
  position: absolute;
  width: 6vw;
  height: 100%;
  background-image: url(${texture_two});
  background-repeat: repeat-y;
  background-size: 100% auto;
  left: 0;
  top: 0;
  pointer-events: none;
`

const Home = () => {
  return (
    <Container>
      <Backdrop />
      <Image
        src="HomePage/home/black_coffee.png"
        alt="Sound Idea Sessions | Black Coffee"
        className="black-coffee"
      />
      <Image
        src="HomePage/home/ryan_holiday.png"
        alt="Sound Idea Sessions | Ryan Holiday"
        className="ryan-holiday"
      />
      <Texture />
      <Landing />
      <About />
      <EpisodeExtended />
    </Container>
  )
}

export default Home
