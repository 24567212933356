import React from "react"
import styled, { keyframes } from "styled-components"

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

const Container = styled.svg`
  enable-background: new 0 0 1538 1561.2;
  transform-origin: center center;
  .st0 {
    fill: #fff;
  }
  .inner-spin {
    animation: ${spin} 7500ms linear infinite;
    transform-origin: center center;
  }
  .outer-spin {
    animation: ${spin} 100000ms linear infinite;
    transform-origin: center center;
  }
`

const Wheel = () => {
  return (
    <Container x="0px" y="0px" viewBox="0 0 1538 1561.21">
      <g className="inner-spin">
        <g>
          <g>
            <g>
              <path
                className="st0"
                d="M677.6,747.1c-3.3,0-6.6-0.8-9.6-2.6l-79.2-45.7c-9.2-5.3-12.4-17.1-7.1-26.4c5.3-9.2,17.2-12.3,26.4-7.1
                l79.2,45.7c9.2,5.3,12.4,17.1,7.1,26.4C690.7,743.7,684.2,747.1,677.6,747.1z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                className="st0"
                d="M939.7,898.5c-3.3,0-6.6-0.8-9.6-2.6l-79.2-45.7c-9.2-5.3-12.4-17.1-7.1-26.4c5.4-9.2,17.2-12.4,26.4-7.1
                l79.2,45.7c9.2,5.3,12.4,17.1,7.1,26.4C952.8,895,946.3,898.5,939.7,898.5z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                className="st0"
                d="M769,694.3c-10.7,0-19.3-8.6-19.3-19.3v-91.4c0-10.7,8.6-19.3,19.3-19.3s19.3,8.6,19.3,19.3v91.5
                C788.3,685.7,779.7,694.3,769,694.3z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                className="st0"
                d="M769,997c-10.7,0-19.3-8.6-19.3-19.3v-91.5c0-10.7,8.6-19.3,19.3-19.3s19.3,8.6,19.3,19.3v91.5
                C788.3,988.3,779.7,997,769,997z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                className="st0"
                d="M860.5,747.1c-6.7,0-13.1-3.5-16.7-9.6c-5.3-9.2-2.2-21,7.1-26.4l79.2-45.7c9.2-5.3,21-2.2,26.4,7.1
                c5.3,9.2,2.2,21-7.1,26.4l-79.3,45.7C867.1,746.3,863.8,747.1,860.5,747.1z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                className="st0"
                d="M598.4,898.5c-6.7,0-13.1-3.5-16.7-9.6c-5.3-9.2-2.2-21,7.1-26.3l79.2-45.7c9.2-5.3,21-2.2,26.3,7.1
                c5.3,9.2,2.2,21-7.1,26.3L608,896C605,897.6,601.7,898.5,598.4,898.5z"
              />
            </g>
          </g>
        </g>
      </g>
      <g className="outer-spin">
        <path
          className="st0"
          d="M169.2,344.1l56.2,30.4l-1.1,2c-29.4-14.3-74.6,9-85.9,29.7l-1.5,2.7l145.3,78.7c17,9.2,26.6,12.8,32.1,2.8
          l2.2,1.2l-39.9,73.7l-2.2-1.2c5.4-10-2.9-16.1-19.9-25.3l-145.3-78.6l-1.5,2.7c-11.4,21-6,71.3,22,88.2l-1.1,2l-56.1-30.5
          L169.2,344.1z"
        />
        <path
          className="st0"
          d="M602.3,73.6l0.6,2.2c-11.5,3.2-17.7,5.2-11,29.3l37.5,135.2c7.2,26,14.2,26.7,25.7,23.5l0.7,2.5l-90.3,25
          l-0.7-2.5c11.5-3.2,17.1-7.4,9.9-33.4l-37.5-135.1c-6.7-24.1-12.8-22.7-24.6-19.4l-0.6-2.2L602.3,73.6z"
        />
        <path
          className="st0"
          d="M1111.8,313.4l-26.2,51.1c-9.2,0.6-38.2-6.6-64.2-21.7c-52.6-30.4-80.2-90.6-48.1-146.1
          c32.1-55.6,98-61.8,150.6-31.5c31.7,18.3,47.8,36.4,51,44.5l-31.1,48.3l-2-1.1c22-55.2-1.2-79.1-19.2-89.5
          c-27.3-15.8-49.9-3.2-88.8,64.2c-39,67.6-37.7,93.7-11.1,109c16.7,9.7,50.6,15.7,87.2-28.4L1111.8,313.4z"
        />
        <path
          className="st0"
          d="M1446.7,643.9l-2.3,0.3c-1.8-12.4-0.9-14.2-21.4-11.3l-155.8,22.3c-19.1,2.7-17.8,3.7-16.1,15.8l-2.5,0.4
          l-11.4-79.6l2.5-0.4c1.7,11.8,0.5,11.7,19.6,9l155.8-22.3c17.4-2.5,19.5-2.2,17.7-14.3l2.2-0.3L1446.7,643.9z M1287.1,775.7
          c0,0,9.8-10,88.3-88.4c55.7,38.5,85.4,71.6,87.8,88.7l2.2-0.3l-13.3-92.8l-2.3,0.3c2.5,17.7-34.9,28.5-59.8,11.4l-57.4-40.6
          c0,0-14.9,14.5-35.6,34.1c-38.5,35.6-40.6,39.4-36.3,69.2l7.2,50l2.5-0.4C1270.2,799.7,1269.8,793.1,1287.1,775.7z"
        />
        <path
          className="st0"
          d="M1107.3,1215.5l-40.5-38.1l113.6-120.8l1.9,1.8c-8.2,8.7,1.1,23.7,15.2,36.9l88.8,83.5
          c12.8,12.1,29.6,22.4,38.2,13.3l1.7,1.6l-111.1,118.1l-42.4-39.9l1.8-1.9c40.3,37.9,93.7-18.3,93.7-18.3l7-7.5l-69.3-65.2
          l-15.8,16.8c-0.2,0.2-21.4,21.6,1.5,47.4l-1.8,1.9l-50.7-47.7l1.8-1.9c27,21.1,47.2-1.2,47.4-1.5l15.8-16.8l-70.8-66.6l-7,7.4
          c0,0-57.9,57-17,95.6L1107.3,1215.5z"
        />
        <path
          className="st0"
          d="M605.2,1475.8l3.7-63.8l2.3,0.1c-0.5,32.7,39.8,63.8,63.3,65.2l3.1,0.2l9.7-165c1.1-19.3,0.3-29.6-11-30.2
          l0.1-2.6l83.6,4.9l-0.1,2.6c-11.3-0.7-13.4,9.5-14.5,28.7l-9.7,165l3.1,0.2c23.8,1.4,67.2-24.8,70.5-57.3l2.3,0.1l-3.7,63.8
          L605.2,1475.8z"
        />
        <path
          className="st0"
          d="M286.5,1046c0,0,27.8-2,57.7,37c33.6,43.7,35,69.4,35,69.4l-43.3,33.2l-1.6-2c12.6-15.4,34.9-63.7,7.7-99
          c-18.5-24.1-53.7-7.9-53,34.6l4.8,51.5c4.5,47.4-16.9,76.4-55.7,75.7c0,0-26.6,0.8-55-36.2c-29.6-38.5-30.6-61.3-30.6-61.3
          l37.8-29.1l1.6,2c-12.8,17-34.4,50.8-6.7,86.8c18.7,24.3,51.1,10.6,47.7-33.4l-3.7-52.3C226.2,1078,249,1047.9,286.5,1046z"
        />
      </g>
    </Container>
  )
}

export default Wheel
