import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { isMobile } from "react-device-detect"

import media from "../../../../styles/media"
import Image from "../../../elements/image"

import Name from "./name"

// import photo from "./photo-s.png"
import underline from "./underline.png"

const Container = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  grid-template-rows: auto auto;
  grid-template-areas: "name name" "photo intro";
  padding: 15vh 0;
  ${media.tablet`
    grid-template-columns: 0.7fr 1.3fr;
    padding: 15vh 0;
  `}
`

const NameContainer = styled.div`
  grid-area: name;
  overflow: hidden;
  margin-bottom: 2rem;
  position: relative;
  ${media.tablet``}
`

const Photo = styled.div`
  grid-area: photo;
  position: relative;
  transform: scale(1.25);
  transform-origin: top left;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 93, 107, 0.99);
    mix-blend-mode: lighten;
  }
`

const Intro = styled.div`
  grid-area: intro;
  padding-right: var(--gutter-s);
  position: relative;
  h4 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    line-height: 1.25;
  }
  p {
    font-size: 1rem;
    a {
      padding-bottom: 0.5rem;
      display: inline-block;
      background-image: url(${underline});
      background-repeat: no-repeat;
      background-position: bottom left;
      background-size: 100% auto;
    }
  }
  ${media.tablet`
    padding-right: 15vw;
    h4 {
      font-size: 1.85rem;
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.25rem;
      line-height: 1.75;
    }
  `}
`

const BlackCoffee = () => {
  return (
    <Container>
      <Photo>
        {/* <img src={photo} alt="SIS" /> */}
        <Image
          src="BlackCoffeePage/black_coffee.png"
          alt="Sound Idea Sessions | Black Coffee"
        />
      </Photo>
      <NameContainer>
        <Name />
      </NameContainer>
      <Intro>
        <h4>
          Black Coffee is, humbly put, a South African DJ and music producer.
          But his talent, countless accomplishments and whirlwind rise to fame
          make this description wholly inadequate.
        </h4>
        <p>
          {!isMobile && (
            <>
              These qualities, a dedicated work ethic and back to back worldwide
              tours are what have made him into arguably one of the most
              prominent producers out of the African continent. Known for his
              penchant for evocative Afro-house, his true talent lies in his
              holistic mission to rewrite musical conventions of that genre.
              Afrocentric yet globally contemporary Black Coffee defies musical
              clichés and modernises African music through harmonious
              collaborations and crisp production. Described as a ‘reserved
              intellectual’ Nkosinathi Innocent Maphumulo - his birth name, had
              always had aspirations of becoming a DJ even from the tender of
              age of 14. The year 1990 was a pivotal time in his life and for
              South Africa, it is the same year apartheid struggle icon Nelson
              Mand...
            </>
          )}
          <Link to="/black-coffee" className="mouse-link">
            continue reading
          </Link>
        </p>
      </Intro>
    </Container>
  )
}

export default BlackCoffee

/* 
  change opacity of img unless master color overlay works 
*/
