import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import media from "../../../../styles/media"

import underline from "./underline.png"

const Container = styled.div`
  padding: var(--gutter-l) var(--gutter-s) calc(2 * var(--gutter-l))
    var(--gutter-s);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  z-index: 1;
  p {
    font-size: 1rem;
    line-height: 1.75;
    text-align: right;
    margin-bottom: 4rem;
    a {
      padding-bottom: 0.5rem;
      display: inline-block;
      background-image: url(${underline});
      background-repeat: no-repeat;
      background-position: bottom left;
      background-size: 100% auto;
    }
  }
  ${media.tablet`
    padding: calc(3 * var(--gutter-l)) var(--gutter-l) var(--gutter-l) var(--gutter-l);
    p {
      font-size: 1.25rem;
      max-width: 50vw;
      a {}
    }
  `}
`

const Video = styled.div`
  width: 100%;
  height: 200px;
  iframe {
    width: 100%;
    height: 100%;
    &.ytp-chrome-top
    &.ytp-show-watch-later-title
    &.ytp-share-button-visible
    &.ytp-show-share-title
    &.ytp-show-cards-title {
      display: none;
    }
  }
  ${media.tablet`
    width: 50vw;
    height: 400px;
  `}
`

const About = () => {
  return (
    <Container name="about" id="About">
      <p>
        Sound Idea Sessions is not another business conference. We’re mixing it
        up by combining thought-leadership and music to create a platform,
        designed to inspire you to live life on your terms and follow your
        dreams while providing you with practical skills for succeeding at a new
        level.
        <br />
        <Link to="/about" className="mouse-link">
          read more
        </Link>
      </p>
      <Video>
        <iframe
          src="https://www.youtube-nocookie.com/embed/TuKkA12jd8k?ecver=1&amp;rel=0"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullscreen
        ></iframe>
      </Video>
    </Container>
  )
}

export default About
