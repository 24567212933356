import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { isMobile } from "react-device-detect"

import media from "../../../../styles/media"
import Image from "../../../elements/image"

import Name from "./name"

import grundge from "./grundge.png"
import underline from "./underline.png"

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  grid-template-rows: auto auto;
  grid-template-areas: "name name" "photo intro";
  padding: 10vh 0 10vh;
  background-image: url(${grundge});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top left;
  ${media.tablet`
    grid-template-columns: 0.7fr 1.3fr;
    padding: 15vh 0;
  `}
`

const NameContainer = styled.div`
  position: relative;
  grid-area: name;
  overflow: hidden;
  margin-bottom: 2rem;
`

const Photo = styled.div`
  grid-area: photo;
  position: relative;
  transform: scale(1.25);
  transform-origin: top left;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 93, 107, 0.99);
    mix-blend-mode: lighten;
  }
`

const Intro = styled.div`
  position: relative;
  grid-area: intro;
  padding-right: var(--gutter-s);
  h4 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    line-height: 1.25;
  }
  p {
    font-size: 1rem;
    a {
      padding-bottom: 0.5rem;
      display: inline-block;
      background-image: url(${underline});
      background-repeat: no-repeat;
      background-position: bottom left;
      background-size: 100% auto;
    }
  }
  ${media.tablet`
    padding-right: 15vw;
    h4 {
      font-size: 1.85rem;
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.25rem;
      line-height: 1.75;
    }
  `}
`

const RyanHoliday = () => {
  return (
    <Container>
      <Photo>
        <Image
          src="RyanHolidayPage/ryan_holiday.png"
          alt="Sound Idea Sessions | Ryan Holiday"
        />
      </Photo>
      <NameContainer>
        <Name />
      </NameContainer>
      <Intro>
        <h4>
          Ryan Holiday is a young, smart, marketing powerhouse-turned-
          motivational-speaker who has attracted fans from every imaginable
          discipline.
        </h4>
        <p>
          {!isMobile && (
            <>
              When it comes to motivation, Holiday makes readers—and
              listeners—take stock of their lives and careers through the
              timeless lens of ancient philosophy, stoicism. His book The Daily
              Stoic distils the ancient wisdom of the stoic philosophers into
              366 practical meditations; each meant to enrich and enliven our
              daily lives—at work, on the field, and in our relationships. In
              Ego Is the Enemy—an instant Wall Street Journal, USA Today, and an
              international bestseller—he explains how “the battle against ego
              must be fought on many fronts,” and provides helpful examples of
              major figures who’ve achieved success by eschewing the spotlight
              and putting the greater good above their own vanity. And in the
              now cult-classic The Obstacle Is the Way, he sho...
            </>
          )}
          <Link to="/ryan-holiday" className="mouse-link">
            continue reading
          </Link>
        </p>
      </Intro>
    </Container>
  )
}

export default RyanHoliday
