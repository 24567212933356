import React from "react"
import styled from "styled-components"
import { animateScroll } from "react-scroll"

import Label from "../../elements/label"

const Container = styled.div``

const Episode = ({ ...props }) => {
  const ScrollDown = () => {
    animateScroll.scrollTo(window.innerHeight, {
      duration: 500,
      smooth: true,
    })
  }
  return (
    <Container {...props}>
      <Label
        fontSize={1}
        fontScaled
        outerRotate={-1}
        innerRotate={-1}
        mLeft={0.5}
        value="EP. 01"
      />
      <br />
      <Label
        mLeft={0}
        pRight={1.5}
        fontSize={1}
        fontScaled
        outerRotate={0}
        innerRotate={0}
        value="Fox Junction"
      />
      {/* <br />
      <Label
        mLeft={0.5}
        fontSize={1}
        fontScaled
        outerRotate={1}
        innerRotate={0}
        value="7 Nov 2019"
      /> */}
      <br />
      <Label
        mLeft={1}
        fontSize={1}
        fontScaled
        outerRotate={-1}
        innerRotate={-2}
        value="&#8595;&#8595;&#8595;"
        className="mouse-link"
        onClick={() => ScrollDown()}
      />
    </Container>
  )
}

export default Episode
