import React from "react"
import styled from "styled-components"

import Label from "../../elements/label"
import Image from "../../elements/image"

import media from "../../../styles/media"

import fox from "./images/fox.png"
import underline from "./images/underline.png"

const Container = styled.div`
  background-color: rgba(63, 63, 63, 0.99);
  position: relative;
  padding: 20vh 0 20vh var(--gutter-s);
  .gatsby-image-wrapper {
    &.background {
      position: absolute !important;
      top: 0;
      left: 0;
      img {
        object-position: 70% 100% !important;
      }
    }
  }
  ${media.tablet`
    padding: 20vh 0 20vh var(--gutter-l);
    .gatsby-image-wrapper {
      &.background {
        img {
          object-position: 50% 100% !important;
        }
      }
    }
  `}
`

const Heading = styled.div`
  transform: translateY(-50%);
  h2 {
    color: rgba(0, 0, 0, 0.9);
    line-height: 0.6;
    font-size: 16vw;
    text-transform: uppercase;
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
    &.fox {
      font-family: "AvenirLTStdBlack";
      letter-spacing: 0px;
      span {
        background-image: url(${fox});
        background-repeat: no-repeat;
        background-position: 60% 40%;
        background-size: 25%;
      }
    }
    &.junction {
      font-family: "butlerblack";
      letter-spacing: -5px;
      .location {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: calc(0px + 115%);
        right: -30px;
        letter-spacing: 1px;
        div {
          margin-left: 0px;
          margin-bottom: 2px;
          text-align: right;
          font-size: 0.5rem;
        }
      }
    }
  }
  ${media.tablet`
    position: relative;
    z-index: 1;
    transform: translateY(35%);
    h2 {
      &.fox {
        letter-spacing: -10px;
        span {
          background-position: 55% 40%;
        }
      }
      &.junction {
        letter-spacing: -10px;
        .location {
          display: flex;
          flex-direction: row;
          top: calc(0px - 40%);
          right: 0px;
          div {
            margin-left: 5px;
            margin-bottom: 0px;
            text-align: left;
            font-size: 0.75rem;
          }
        }
      }
    }
  `}
`

const PhotoGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1.3fr 0.4fr 1.3fr;
  grid-template-rows: repeat(10, auto);
  .p {
    .gatsby-image-wrapper {
      opacity: 0.8;
    }
  }
  .photo-1 {
    grid-area: 1 / 1 / 4 / 3;
  }
  .photo-2 {
    grid-area: 2 / 2 / 5 / 4;
  }
  .photo-3 {
    grid-area: 6 / 2 / 9 / 4;
  }
  .photo-4 {
    grid-area: 7 / 1 / 11 / 3;
  }
  .directions {
    grid-area: 1 / 3 / 2 / 4;
    padding: 1rem 1rem;
    display: flex;
    align-items: flex-end;
    a {
      font-family: "AvenirLTStdBlack";
      color: rgba(0, 0, 0, 0.9);
      font-size: 0.85rem;
      background-image: url(${underline});
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: left bottom;
      padding: 0.75rem 0rem;
    }
  }
  .insta {
    grid-area: 5 / 1 / 6 / 2;
    display: flex;
    align-items: flex-end;
    .link {
      display: flex;
      align-items: center;
      a {
        font-family: "AvenirLTStdRoman";
        color: rgba(0, 0, 0, 0.9);
        padding-top: 1px;
        font-size: 0.85rem;
      }
      svg {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }
  }
  ${media.tablet`
    grid-template-columns: 2fr 0.5fr 2fr 0.3fr 1.5fr 0.4fr 1.2fr;
    grid-template-rows: 0.5fr 0.5fr 0.5fr 1fr 0.5fr 1fr;
    .p {}
    .photo-1 {
      grid-area: 2 / 1 / 6 / 3;
    }
    .photo-2 {
      grid-area: 4 / 2 / 7 / 5;
    }
    .photo-3 {
      grid-area: 3 / 4 / 5 / 7;
    }
    .photo-4 {
      grid-area: 1 / 6 / 4 / 8;
    }
    .directions {
      grid-area: 3 / 3 / 4 / 4;
      padding: 1rem 2rem;
      a {
        font-size: 1rem;
      }
    }
    .insta {
      grid-area: 4 / 7 / 5 / 8;
      .link {
        transform: rotate(-90deg) translate(-10px, 20px);
        transform-origin: left;
        a {
          font-size: 1rem;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  `}
`

const Venue = () => {
  return (
    <Container name="venue">
      <Image
        className="background"
        src="HomePage/venue/background.png"
        alt="Sound Idea Sessions | Venue"
      />
      <Heading>
        <h2 className="fox">
          F<span>O</span>X
        </h2>
        <h2 className="junction">
          Junction
          <div className="location">
            <Label fontScaled value="1 Fox precinct 14 Alexander St" />
            <Label fontScaled value="Ferreiras Dorp Johannesburg" />
            <Label fontScaled value="2001" />
          </div>
        </h2>
      </Heading>
      <PhotoGrid>
        <div className="p photo-1">
          <Image
            src="HomePage/venue/photo-one.jpg"
            alt="Sound Idea Sessions | Venue"
          />
        </div>
        <div className="p photo-2">
          <Image
            src="HomePage/venue/photo-two.jpg"
            alt="Sound Idea Sessions | Venue"
          />
        </div>
        <div className="p photo-3">
          <Image
            src="HomePage/venue/photo-three.jpg"
            alt="Sound Idea Sessions | Venue"
          />
        </div>
        <div className="p photo-4">
          <Image
            src="HomePage/venue/photo-four.jpg"
            alt="Sound Idea Sessions | Venue"
          />
        </div>
        <div className="p directions">
          <a
            href="https://goo.gl/maps/x85EHvYmKXWCg97B9"
            target="_blank"
            rel="noreferrer noopener"
            className="mouse-link"
          >
            Get Directions
          </a>
        </div>
        <div className="p insta">
          <div className="link">
            <svg viewBox="0 0 24 24">
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
            <a
              href="https://www.instagram.com/foxjunction/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              @foxjunction
            </a>
          </div>
        </div>
      </PhotoGrid>
    </Container>
  )
}

export default Venue
