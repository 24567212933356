import React from "react"
import styled from "styled-components"

const Container = styled.div`
  svg {
    enable-background: new 0 0 652.5 201.6;
    .st0 {
      fill: rgba(255, 255, 255, 0.35);
    }
  }
`

const Tagline = ({ ...props }) => {
  return (
    <Container {...props}>
      <svg x="0px" y="0px" viewBox="0 0 652.5 201.6">
        <g>
          <path
            className="st0"
            d="M38.4,66c1,0,1.7,0.2,2.1,0.5c0.4,0.4,0.5,1.1,0.4,2.1c-0.6,4.6,0.4,11,3.1,19.3c1.1,3.2,2.2,6.8,3.3,10.9
		c0.3,1.3,0.5,2.9,0.5,4.7c-0.1,1.2-0.6,2.1-1.5,2.5c-0.9,0.4-1.9,0.3-3-0.3c-0.2-0.2-0.4-0.4-0.6-0.6c-1.1-1.2-2.7-1.4-4.8-0.5
		c-4,2-9.4,2.5-16,1.7c-3.1-0.5-5.9-1.9-8.4-4.2c-2.2-2-3.9-3.8-5.3-5.6C4.2,91,1.5,83.8,0.2,75c-0.6-4.2,0.3-8.4,2.6-12.4
		c2.1-3.5,3.9-6.4,5.4-8.7c3.5-5.4,4.2-11.1,2-17.1c-0.8-2.3-1.6-4.5-2.3-6.7c-1.1-4.1-0.2-7.7,2.8-10.6c2.2-1.9,4.2-3.3,6.1-4.2
		c5.2-2.4,11.1-2.7,17.8-0.9c2.8,0.7,5.2,2.4,7.1,5c4.8,6.3,8,13.7,9.6,22.2c0.1,0.4,0.2,0.9,0.3,1.4c0.1,2.1-0.2,3.5-0.9,4.4
		c-0.7,0.8-2.1,1.2-4.2,1.2c-2.2,0-3.8,0-4.8,0c-1.2,0-2.1-0.2-2.5-0.6c-0.4-0.4-0.6-1.2-0.5-2.3c0.2-3.6-0.9-8.3-3.3-14
		c-2.1-5-5.8-6.9-11.2-5.9c-2.6,0.5-3.7,2.1-3.3,4.7c0,0.2,0.4,1.4,1.1,3.6c1,3.3,1.6,5.1,1.6,5.3c1.2,4.4,0.8,8.5-1.4,12.3
		c-2.1,3.7-3.7,6.5-5,8.2c-4.4,6.4-5.5,13.6-3.1,21.4c1.1,3.3,1.9,5.5,2.3,6.7c1.9,4.6,5.2,6.6,10.1,6.2c3.6-0.3,5-2.2,4.2-5.6
		c-0.1-0.6-0.4-1.5-0.8-2.7c-0.4-1.2-0.7-2-0.8-2.6c-0.6-2.1-1.2-4.1-1.7-6.2c-0.4-2.1-1.6-3.3-3.6-3.6c-1.3-0.2-2.2-0.5-2.6-0.9
		c-0.4-0.4-0.6-1.2-0.5-2.3c0.1-2.6,1.3-4,3.6-4.2c0.3,0,2.7,0,7.3,0v-0.2C35.9,65.9,38.1,65.9,38.4,66z"
          />
          <path
            className="st0"
            d="M79.4,13.9c4.7,0.3,8.8,2.1,12.5,5.5c4.7,4.5,8.3,9,11,13.7c1.8,3.3,2.8,6.1,3.1,8.5c0.2,2.4-0.2,4.5-1.2,6.3
		c-1,1.8-2.7,3.7-5.2,5.7c-1.1,0.9-2.4,1.9-3.7,2.8s-3,2.1-4.9,3.3c-1.9,1.2-3.2,2.1-4,2.6c-0.2,0.2-0.6,0.5-1.1,0.8
		c-0.6,0.3-0.9,0.5-1.1,0.6c-1.3,0.8-2.1,1.6-2.4,2.3c-0.3,0.7-0.3,1.8,0.1,3.2c1.1,3.3,2.4,6.3,3.8,8.8c0.8,1.3,2,3.1,3.6,5.5
		s2.6,3.8,3,4.3l6.6,10.5c0.2,0.3,0.5,0.9,0.8,1.7c0.7,1.7,0.9,2.9,0.5,3.6c-0.4,0.7-1.5,1-3.4,1c-1.3,0.1-3.4,0.1-6.2,0
		c-2,0-3.4-0.9-4.1-2.6c-0.4-1.1-0.8-2.2-1.3-3.2c-0.5-1-1.1-2.2-1.9-3.5c-0.8-1.3-1.4-2.3-1.8-2.8c-0.4-0.6-1.1-1.6-2.2-3.3
		c-1.1-1.6-1.7-2.6-1.8-2.9c-2.4-3.7-4.8-7.4-7.2-11.3c-0.3-0.6-1-2.3-2.1-5.2c-0.6-1.4-1.4-2-2.4-1.8c-1.2,0.2-1.8,1-1.8,2.3
		c0,3.2,0.1,5.4,0.5,6.4c0.3,1.4,0.9,3.5,1.8,6.2c0.9,2.7,1.5,4.7,1.9,5.8c1.3,5.1,2.2,8.8,2.6,11.3c0.2,1.7,0,2.9-0.5,3.5
		c-0.6,0.6-1.8,0.9-3.7,0.9c-2.4,0.1-4.3,0.1-5.6,0c-1.1-0.1-1.9-0.3-2.3-0.7c-0.4-0.4-0.7-1-0.8-2.1c-0.1-1.5-0.4-3.2-0.8-5.1
		c-0.4-1.9-0.7-3.3-1-4.3c-0.3-1-0.8-2.7-1.7-5.1c-0.9-2.4-1.3-3.9-1.4-4.4c-1.5-4.4-2.3-8.1-2.3-11.3c0-3.6,0.9-6.9,2.7-10.1
		c2-3.4,3.7-6.1,5-7.9c3.7-5.5,4.3-11.4,2-17.8c-0.4-1-0.9-2.5-1.5-4.3c-0.6-1.9-1-2.9-1.1-3.1c-1.1-3.1-1.4-6.8-0.9-10.8
		c0.2-1.5,0.6-2.6,1.3-3.1c0.7-0.6,1.7-0.8,3.3-0.8c0.3,0,3.3,0,9,0C76,13.7,79,13.8,79.4,13.9z M89.4,49c2.7-2.3,3.7-5.3,2.9-8.8
		c-0.8-4-3-7.3-6.6-9.9c-3.6-2.6-7.3-3.8-11.3-3.3c-1.3,0.1-2.2,0.4-2.5,0.9c-0.4,0.5-0.3,1.4,0.1,2.6c0.2,0.8,0.6,2,1.1,3.5
		c0.6,1.5,0.9,2.6,1.1,3.4c1.4,4.8,1.2,9.1-0.8,13.1c-0.7,1.6-0.9,2.7-0.7,3.1s1.2,0.7,3,0.7c0.1,0,0.3,0,0.5,0h0.1
		C81.1,54.1,85.5,52.4,89.4,49z"
          />
          <path
            className="st0"
            d="M115.6,93.3c-3.9-5.5-6.4-12.4-7.6-20.6c-0.7-4.6,0.3-9,2.9-13.3l4.9-7.9c3.4-5.2,4-10.7,2-16.5
		c-0.8-2.3-1.5-4.5-2.1-6.6c-1.2-4.1-0.4-7.6,2.6-10.7c2.6-2.6,5.7-4.4,9.2-5.2c4.2-1,8.8-1.1,13.9-0.3c3.8,0.6,6.8,2.6,9.2,5.8
		c4.2,5.6,7.2,12.4,9.2,20.3c0.8,3.8,0.2,7.4-1.7,10.9c-0.5,0.9-1.3,2.3-2.4,4.1c-1.1,1.8-1.9,3.3-2.4,4.3c-1.8,3.3-3.1,5.8-3.7,7.6
		c-1.1,3-1.1,6.7,0.2,11.3c0.2,0.8,0.8,2.9,1.7,6.1c0.1,0.3,0.3,0.7,0.5,1.3c0.2,0.6,0.4,1,0.5,1.3c1.5,4.7,1.2,8.7-0.9,11.9
		c-2.1,3.3-5.7,5.2-10.6,5.8c-0.2,0-2,0.1-5.5,0.3C127.3,103.9,120.8,100.6,115.6,93.3z M123.6,83.4c1,3.1,2.4,5.1,4.1,6.2
		c1.7,1.1,4.2,1.5,7.3,1.3c3.8-0.3,5.1-2.2,4.1-5.8c-0.1-0.4-0.8-2.4-2-6.1c-2.6-8-1.5-15.3,3.2-21.9c1.1-1.7,2.7-4.4,4.9-8.1
		c1.8-3.4,2.3-6.9,1.4-10.6c-0.8-3.4-1.8-6.5-3.1-9.5c-1.5-3.6-4.2-5.3-7.9-5.2c-0.3,0-0.7,0-1.3,0s-1,0-1.4,0c-3.7,0.3-5,2.2-4,5.8
		c0.2,1,0.8,2.8,1.7,5.3c2,5.8,1.4,11.3-1.8,16.4l-5.2,8.4c-2,3.5-3,6.7-2.9,9.6C120.6,73.3,121.6,78,123.6,83.4z"
          />
          <path
            className="st0"
            d="M207.5,53.4c6.3-6.5,8.8-13.1,7.4-19.8c-0.2-0.8-0.7-3.9-1.5-9.3c-0.5-3-0.1-6.7,1.4-11c0.6-2,2-2.9,4-2.9
		c3.7-0.1,6-0.1,6.8,0c2.4,0.1,3.2,1.1,2.5,3.1c-1.8,4.9-1.8,10.9,0,18.3c1.7,7,0.2,13.3-4.5,18.9c-0.6,0.7-1.5,1.9-2.8,3.5
		c-1.2,1.6-2.2,2.8-2.8,3.5c-5.1,6-6.9,13.3-5.4,22c0.1,0.7,0.3,1.8,0.5,3.3c0.3,1.5,0.5,2.8,0.7,4c0.2,1.2,0.4,2.4,0.5,3.5
		c0.3,2.6,0.4,5.2,0.3,7.9c0,1.9-0.3,3.1-1,3.7c-0.7,0.6-1.9,0.9-3.6,0.9c-2.7,0.1-5.3,0.1-7.7,0c-1.5,0-2.6-0.2-3.1-0.7
		c-0.5-0.5-0.9-1.4-1.2-2.9c-0.8-4.4-3-10.3-6.7-17.5c-0.1-0.1-0.7-0.7-1.7-1.9l-0.6,0.3c0.2,2,0.3,3,0.3,3.1c0.1,0.6,0.4,2,0.8,4
		c0.4,2.1,0.7,3.7,0.9,4.8c0.3,1.9,0.5,3.9,0.5,6c-0.1,1.9-0.5,3.1-1.1,3.7c-0.6,0.6-1.8,0.9-3.6,0.9c-3.1,0.1-5.8,0.1-8.1,0
		c-1.3,0-2.3-0.2-2.8-0.7c-0.5-0.5-0.9-1.3-1.2-2.6c-0.7-3.3-2.4-7.7-5.1-13.2c-2.6-5.3-4.4-9.1-5.4-11.6
		c-2.7-6.2-2.5-12.2,0.5-18.1c0.7-1.3,1.8-3.7,3.3-7c2.1-4.6,2.1-9.2,0-13.8c-0.6-1.2-2.1-4.3-4.5-9.1c-2.4-4.6-3.5-8.8-3.4-12.5
		c0-1.5,0.3-2.6,0.8-3.2c0.5-0.6,1.5-0.9,3.1-0.9c2.6-0.1,4.6-0.1,6.2,0c1.1,0,2,0.2,2.5,0.7c0.5,0.5,0.8,1.2,0.8,2.2
		c-0.1,2.2,0.2,4.4,0.9,6.7c0.7,2.3,1.4,4.2,2.1,5.7c0.7,1.5,1.8,3.5,3.1,6c0.3,0.5,0.9,1.8,1.9,3.9c2.3,4.9,2.4,9.6,0.3,14.2
		c-0.5,1.2-0.7,2.5-0.6,3.7c1-0.5,1.5-0.8,1.5-0.9c5.4-6,7.3-11.9,5.9-17.8c-0.8-4-1.5-7.4-2.2-10.2c-0.7-3.5-0.5-7.2,0.8-11
		c0.3-1.2,0.8-2.1,1.4-2.6c0.6-0.5,1.5-0.7,2.8-0.7c2.3-0.1,4.5-0.1,6.7,0c1.2,0,2.1,0.3,2.6,0.8s0.7,1.4,0.6,2.6
		c-0.2,4.5,1.1,9.8,4,15.6c0.6,1.4,1.5,3.5,2.6,6.2c2.1,4.8,2,9.4-0.3,13.9c-0.1,0.1-0.7,1.5-1.7,4.2l0.6,0.5
		C207,53.8,207.4,53.5,207.5,53.4z"
          />
          <path
            className="st0"
            d="M276.4,9.2c1.2,0,2,0,2.5,0c1.5,0.3,2.3,1.2,2.3,2.8c0,2.4-0.1,5.1-0.3,8.1c-0.1,0.9-0.4,1.5-0.8,1.8
		c-0.4,0.3-1.1,0.5-2.1,0.5c-0.1,0.1-1.6,0.1-4.5,0.1c-2.9,0-4.4,0-4.5,0c-2.5,0-3.9,0.3-4.3,0.9c-0.4,0.6-0.2,2,0.6,4.1
		c1,3.3,1.8,5.8,2.3,7.7c1,3.9,0.6,7.6-1.4,11.1c-1.9,3.5-3.5,6.2-4.8,7.9c-4.8,6.7-5.8,14.4-3.1,22.8c2.4,6.8,3.6,10.6,3.7,11.2
		c0.8,3,1.4,5.9,1.7,8.9c0.1,1.4-0.1,2.4-0.6,3c-0.5,0.6-1.5,0.8-3.1,0.8c-2,0.1-4,0.1-5.8,0c-1.3,0-2.2-0.2-2.8-0.6
		c-0.5-0.4-0.8-1.2-0.8-2.5c-0.2-3.5-1.5-8.8-3.8-15.9c-1.2-3.5-2.1-6.6-2.8-9.4c-1.3-5.6-0.5-10.8,2.5-15.5c2.5-4.1,4.1-6.7,4.9-8
		c3.6-5.4,4.2-11.1,2-17.2l-2-5.7c-0.9-2.7-2.8-3.9-5.5-3.8c-1.6,0-4.8,0-9.3,0c-1.9-0.1-3.3-0.5-4-1.1c-0.7-0.7-1.1-1.9-1.1-3.8
		c0-1.5,0.2-3.2,0.5-4.9c0.2-1.3,0.6-2.2,1.1-2.8s1.5-0.8,2.8-0.8c0.6,0,3.5,0,8.6,0c1.4,0,5.6,0,12.4,0
		C267.9,9.2,274.4,9.2,276.4,9.2z"
          />
          <path
            className="st0"
            d="M316.9,46.1c2.1,0,3.6-1,4.6-3.1c1.3-2.8,1.8-5.4,1.2-8c-0.2-0.8-1-3.8-2.5-8.8c-0.2-0.5-0.5-1.3-0.8-2.5
		c-0.4-1.1-0.7-2.1-0.9-3s-0.4-1.7-0.5-2.4c-0.3-2.1-0.3-4.8,0.2-8.2c0.2-2.1,1.3-3.1,3.4-3.2c2.4-0.1,4.7-0.1,7.1,0
		c2.2,0.1,3,1.2,2.6,3.2c-0.8,4.5,0,10.1,2.5,16.7c0.4,0.8,1,2.6,1.7,5.2c1.4,4.6,1,8.9-1.4,13c-2.1,3.6-3.6,6.1-4.6,7.6
		c-4.7,6.7-5.8,14-3.1,21.9c0.1,0.3,0.6,1.9,1.5,4.6c0.9,2.8,1.6,4.9,2,6.5c0.9,3.2,1.6,6.2,2,8.9c0.2,1.6,0,2.8-0.5,3.5
		c-0.6,0.7-1.7,1-3.5,1c-2.4,0.1-4.4,0.1-6.2,0c-1-0.1-1.8-0.4-2.2-0.8c-0.5-0.4-0.7-1.2-0.7-2.3c-0.2-3.6-1.2-8.1-3.1-13.4
		c-1.2-3.6-2-5.7-2.2-6.3c-1.6-5-2.3-9.5-2-13.3c0.2-1.2,0.1-2.1-0.3-2.5c-0.4-0.5-1.2-0.7-2.5-0.7c-3.7-0.1-7.1-0.1-10.2,0
		c-2.8,0.1-4.2,1.5-4.2,4.2c-0.1,3.2,0.2,6,0.8,8.5c0.3,1.4,0.9,3.5,1.8,6.1c0.9,2.6,1.5,4.4,1.8,5.3c0.5,2,1.3,5.3,2.5,10
		c0.2,0.7,0.3,1.5,0.2,2.5c-0.2,1.8-1,2.6-2.5,2.6c-3.1,0.1-5.9,0.1-8.3,0c-1.5,0-2.4-0.9-2.5-2.6c0-1.1-0.1-2.2-0.2-3.3
		c-0.2-1.1-0.4-2.3-0.9-3.7c-0.4-1.4-0.7-2.4-0.8-3c-0.2-0.6-0.5-1.8-1.2-3.6c-0.6-1.8-1-2.9-1.1-3.2c-1-3.3-2-6.7-2.8-10.3
		c-0.9-4.4-0.2-8.7,2.3-13c1.6-3.1,3.4-6,5.2-8.6c3.8-5.5,4.5-11.6,2-18c-1.6-4.6-2.8-8.4-3.4-11.4c-0.2-1.2-0.2-4,0.2-8.2
		c0.2-1.9,1.2-2.8,3.1-2.9c2.9-0.1,5.4-0.1,7.7,0c1.6,0.1,2.5,1,2.5,2.8c0,0.3-0.1,0.7-0.2,1.2c-0.6,3.8-0.2,7.8,1.2,11.9
		c0.2,0.6,0.5,1.5,0.8,2.5c0.4,1.1,0.7,2.1,1,2.9c0.3,0.9,0.6,1.7,0.9,2.5c1.3,4.3,1.3,8.3,0,12c-0.5,1.3-0.6,2.2-0.4,2.6
		c0.3,0.4,1.2,0.7,2.7,0.8c0.1,0,0.3,0,0.6,0h4.3v-0.2C315,46.1,316.6,46.1,316.9,46.1z"
          />
          <path
            className="st0"
            d="M390,45c2.1,0,3.6-1,4.6-3.1c1.3-2.8,1.7-5.4,1.2-8c-0.2-0.8-1-3.7-2.5-8.8c-0.2-0.5-0.5-1.3-0.8-2.5
		c-0.4-1.1-0.7-2.1-0.9-3s-0.4-1.7-0.5-2.4c-0.3-2.1-0.3-4.8,0.2-8.2c0.2-2,1.3-3.1,3.4-3.2c2.4-0.1,4.7-0.1,7.1,0
		c2.2,0.1,3,1.2,2.6,3.2c-0.8,4.5,0,10,2.5,16.6c0.4,0.8,1,2.6,1.7,5.2c1.4,4.6,1,8.9-1.4,12.9c-2.1,3.6-3.6,6.1-4.6,7.5
		c-4.7,6.7-5.7,13.9-3.1,21.8c0.1,0.3,0.6,1.8,1.5,4.6c0.9,2.8,1.6,4.9,2,6.5c0.9,3.2,1.6,6.1,2,8.9c0.2,1.6,0,2.8-0.5,3.5
		c-0.6,0.7-1.7,1-3.5,1c-2.4,0.1-4.4,0.1-6.1,0c-1-0.1-1.8-0.4-2.2-0.8c-0.5-0.4-0.7-1.2-0.7-2.3c-0.2-3.6-1.2-8-3.1-13.4
		c-1.2-3.6-1.9-5.7-2.2-6.3c-1.6-5-2.3-9.4-2-13.2c0.2-1.2,0.1-2.1-0.3-2.5c-0.4-0.5-1.2-0.7-2.5-0.7c-3.7-0.1-7.1-0.1-10.1,0
		c-2.8,0.1-4.2,1.5-4.2,4.2c-0.1,3.2,0.2,6,0.8,8.5c0.3,1.4,0.9,3.5,1.8,6.1c0.9,2.6,1.5,4.4,1.8,5.3c0.5,1.9,1.3,5.3,2.5,10
		c0.2,0.7,0.3,1.5,0.2,2.5c-0.2,1.7-1,2.6-2.5,2.6c-3.1,0.1-5.8,0.1-8.3,0c-1.5,0-2.4-0.9-2.5-2.6c0-1.1-0.1-2.2-0.2-3.3
		c-0.2-1.1-0.4-2.3-0.9-3.7c-0.4-1.4-0.7-2.4-0.8-3c-0.2-0.6-0.5-1.8-1.2-3.6c-0.6-1.8-1-2.8-1.1-3.2c-1-3.3-2-6.7-2.8-10.3
		c-0.9-4.4-0.2-8.7,2.3-12.9c1.6-3.1,3.4-5.9,5.2-8.6c3.8-5.5,4.5-11.5,2-18c-1.6-4.6-2.8-8.4-3.4-11.4c-0.2-1.2-0.2-3.9,0.2-8.1
		c0.2-1.8,1.2-2.8,3.1-2.9c2.9-0.1,5.4-0.1,7.7,0c1.6,0.1,2.5,1,2.5,2.8c0,0.3-0.1,0.7-0.2,1.2c-0.6,3.8-0.2,7.7,1.2,11.8
		c0.2,0.6,0.5,1.5,0.8,2.5c0.4,1.1,0.7,2.1,1,2.9c0.3,0.9,0.6,1.7,0.9,2.5c1.3,4.3,1.3,8.3,0,12c-0.5,1.3-0.6,2.2-0.4,2.6
		c0.3,0.4,1.2,0.7,2.7,0.8c0.1,0,0.3,0,0.6,0h4.3V45C388.1,45,389.8,45,390,45z"
          />
          <path
            className="st0"
            d="M440.4,3.7c1.4,0,2.4,0.2,2.9,0.7c0.5,0.5,0.7,1.4,0.7,2.7c-0.2,4.5,1,9.5,3.7,15c1.3,3,2.5,5.9,3.6,8.8
		c1.2,3.6,1.1,7.2-0.5,10.7c-1.7,3.5-3,6.3-4.2,8.4c-3.5,6.2-3.9,12.7-1.1,19.5c0.5,1.2,1.3,3.1,2.5,5.7c1.1,2.6,2,4.5,2.5,5.7
		c0.8,2.2,2,5.5,3.4,9.9c0.2,0.5,0.4,1.4,0.5,2.6c0,1.7-0.7,2.5-2.2,2.6c-2.7,0.1-5.4,0.1-8,0c-1.3-0.1-2.2-1.1-2.6-2.9
		c-0.6-2.4-1.1-4.1-1.4-5.3c-0.7-2.5-2.4-3.7-5-3.7c-4.8,0-7.3,0-7.4,0c-1.2,0.1-2.1,0.4-2.5,0.8c-0.4,0.4-0.6,1.2-0.5,2.5
		c0,0.2,0,1.1,0.1,2.8c0.1,1.7,0,2.6-0.1,2.8c-0.1,1-0.4,1.8-0.8,2.2c-0.4,0.5-1.2,0.7-2.3,0.7c-1.2,0.1-3.4,0.1-6.3,0
		c-1.8,0-2.6-0.7-2.5-2.2c0.2-2.5,0.1-5.1-0.3-7.8c-0.4-2.7-1-5.7-1.8-8.9c-0.8-3.2-1.3-5.7-1.6-7.6c-0.9-6.4,0.4-12.2,4-17.3
		l6.2-8.5c4-5.2,5.3-10.9,3.7-17.3c-0.5-1.7-1.1-4-1.7-7.1c-0.9-4-0.8-7.9,0.3-11.6c0.4-1.5,0.9-2.6,1.5-3.1s1.7-0.8,3.2-0.8
		c0.4,0,2.7,0,6.8,0C437.8,3.7,440.2,3.7,440.4,3.7z M435,27.1c-0.1,1.8-0.2,2.7-0.2,2.8c0.4,4.3-0.6,8.3-3.1,11.9l-3.6,5
		c-3.2,4.5-4.9,7-5.1,7.3c-2.5,3.8-3.6,8.2-3.2,13.2c0.2,2.6,1.3,3.9,3.4,4c2.3,0.2,4.7,0.2,7.4,0c1.4-0.1,2.1-0.9,1.9-2.5
		c0-0.4-0.1-0.9-0.3-1.5c-2.1-5.9-1.4-11.8,1.9-17.6c1.2-2.4,2.5-5.2,3.9-8.4c1.4-3.6,1.3-7.5-0.5-11.6c0-0.1-0.6-1-1.7-2.6
		L435,27.1z"
          />
          <path
            className="st0"
            d="M489.3,31.8c-0.6-0.4-1.3-2-2-4.9c-0.4-1.6-1.3-3.8-2.5-6.8c-0.9-2.4-2.5-4-4.6-4.9c-2-0.8-3.7-0.7-5,0.5
		c-1.3,1-1.6,2.6-0.9,4.6l2.2,6.8c1.8,5.3,1.2,10.4-1.7,15.1l-5,8.2c-3.8,5.8-4.7,12-2.8,18.9c0.7,2.8,1.6,5.7,2.7,8.5
		c0.9,2.5,2.5,4.2,4.7,5.2c2,0.9,3.7,0.9,5-0.2c1.4-0.9,1.7-2.5,1.1-4.7c-0.2-0.6-0.6-1.8-1.2-3.6c-0.6-1.8-1-3.2-1.2-4.2
		c-0.4-1.5-0.4-2.5,0-3.1c0.4-0.6,1.4-0.9,3-1c1.7,0,3.2,0,4.7,0c1.5,0.1,2.5,0.4,3.1,0.8c0.6,0.4,1.1,1.3,1.5,2.7l2.7,8.6
		c1.5,4.5,1.2,8.4-0.9,11.7c-2,3.3-5.4,5.3-10.1,6c-5.8,0.9-10.8-0.4-15.1-4.1c-3.7-2.9-6.4-6.4-8.3-10.4c-2.4-5.2-4-10.5-4.9-15.7
		c-0.7-4.6,0-9,2.2-13c0.2-0.5,1.3-2.3,3.1-5.3l3.3-5.3c2.8-4.7,3.4-9.6,1.7-14.6l-2.4-7.1c-1.7-5.2-0.4-9.6,3.8-13
		c3.9-3.2,8.2-4.9,12.9-4.9c5.2,0,9.4,1.9,12.6,5.8c4.2,5.4,7.3,12.3,9.4,20.6c0.3,1.3,0.3,2.1-0.2,2.7c-0.4,0.5-1.3,0.8-2.7,0.9
		c-0.1,0-1,0-2.8,0C491.7,32.4,489.9,32.2,489.3,31.8z"
          />
          <path
            className="st0"
            d="M528,32.4c1.2-2.8,2.2-5.3,2.9-7.5c0.2-0.7,0.7-2.7,1.4-6c1-4.6,3.4-9.3,7.1-14.1c1.1-1.4,2.5-2.2,4.1-2.3
		c3.8-0.1,6.7-0.1,8.7,0c2.3,0.1,2.9,1.2,1.7,3.2c-0.1,0.3-0.6,1-1.4,2c-2.4,3.4-4,7.3-5.1,11.7c-0.6,2.8-1.1,4.8-1.4,6
		c-1.2,5.2-3.7,9.6-7.4,13c-5.7,5.4-7.1,12.5-4.1,21.3c0.7,2.1,2.6,5.4,5.7,9.8c1,1.4,2.5,3.4,4.5,5.9c2,2.5,3.4,4.3,4.2,5.4
		c2.3,3.3,4.3,6.3,6,9.2c1.4,2.7,0.7,4-2.3,4.1c-0.6,0-3,0-7.1,0c-1.8,0-3.4-1-4.6-2.9c-2.4-4-5-8-7.8-12c-0.7-1-1.9-2.5-3.5-4.5
		c-1.6-2-2.8-3.6-3.5-4.7c-0.4-0.6-2.5-3.8-6.1-9.7c-1.1-1.9-2.4-2.1-3.7-0.5c-2.4,2.7-3.1,5.5-2.1,8.4c0.1,0.3,0.6,1.7,1.4,4.1
		c0.8,2.5,1.4,4.4,1.8,5.7c1.4,5.1,2.3,8.8,2.6,11.2c0.3,1.8,0.2,3.1-0.4,3.7c-0.6,0.6-1.8,0.9-3.8,0.9c-1.1,0-3.2,0-6.1,0
		c-1.7-0.1-2.7-1-2.8-2.8c0-1.5-0.2-3.2-0.6-5.1c-0.4-1.9-0.8-3.4-1.1-4.5c-0.4-1.1-0.9-2.8-1.7-5.1c-0.8-2.3-1.3-3.8-1.6-4.5
		c-0.7-2.2-1.3-4.4-1.7-6.4c-1-5.4-0.3-10.2,2.3-14.4c2.1-3.7,3.8-6.4,5.1-8.3c3.8-5.5,4.5-11.5,2-18c-0.5-1.6-1.4-4.2-2.6-7.8
		c-1.1-3.2-1.4-6.8-0.8-10.9c0.2-1.3,0.6-2.3,1.2-2.8c0.6-0.6,1.6-0.8,2.9-0.8c2.3-0.1,4.3-0.1,6.1,0c1.2,0,2.1,0.2,2.5,0.7
		c0.5,0.5,0.6,1.3,0.4,2.5c-0.7,4.3,0.2,10,2.8,17.2c0.1,0.2,0.9,3.4,2.3,9.7c0,0.1,0.2,0.8,0.6,2.1C526.4,34.7,527.4,33.9,528,32.4
		z"
          />
          <path
            className="st0"
            d="M567.9,92.9c-1.6,0-2.7-0.2-3.2-0.7c-0.5-0.5-0.8-1.6-1-3.3c-0.6-3.2-1.6-7.3-2.9-12.5
		c-0.3-1.1-0.9-2.9-1.7-5.4c-0.8-2.5-1.4-4.4-1.7-5.7c-1.5-6.2-0.6-11.9,2.8-17.2l4.9-7.7c3.1-4.8,3.7-10,1.8-15.5l-3.1-8.9
		c-1.1-3.3-1.4-7-0.8-11.2c0.2-1.3,0.6-2.3,1.2-2.8c0.6-0.6,1.6-0.8,3-0.8c8.4-0.1,17.3-0.1,26.6,0c1.4,0,2.4,0.2,2.8,0.7
		c0.4,0.5,0.6,1.5,0.6,3c-0.2,2.6-0.3,4.7-0.3,6.5c0,1.2-0.2,2.1-0.5,2.5c-0.4,0.4-1.2,0.6-2.4,0.6c-0.9,0.1-2.9,0.1-6.1,0.1
		s-5.2-0.1-6.2-0.1c-2.2,0.1-3.4,0.5-3.8,1.1c-0.4,0.6-0.3,1.9,0.5,4c1.1,3.3,1.8,5.2,2,5.7c1.3,4.3,1.3,8.3-0.2,12
		c-0.5,1.4-0.6,2.4-0.3,2.8c0.3,0.5,1.2,0.7,2.8,0.7c1,0,2.6,0,4.8,0s3.8,0,4.9,0c1.2,0.1,2,0.4,2.3,0.8c0.3,0.4,0.2,1.2-0.5,2.3
		c-0.3,0.5-1.9,3.2-4.9,8.1c-0.8,1.4-2.4,2.2-4.6,2.2c-1.2,0-2.8,0-4.8,0c-2,0-3.5,0-4.8,0c-1.8,0.1-3.2,0.5-3.9,1.2
		c-0.8,0.7-1.2,1.9-1.2,3.8c0,2.4,0.3,4.6,0.8,6.6c0,0.3,1,3.7,3.1,10.3c0.8,2.6,2.7,3.8,5.5,3.8c5.8,0,10,0,12.5,0
		c1.7,0,3,0.3,3.7,0.8c0.7,0.6,1.2,1.7,1.5,3.5c0.4,1.6,0.7,3.1,0.8,4.5c0.1,1.8-0.1,3.1-0.6,3.6s-1.7,0.8-3.5,0.8
		c-0.7,0-5.1,0-13.1,0C572.8,92.9,568.5,92.9,567.9,92.9z"
          />
          <path
            className="st0"
            d="M625.5,0.5c4.8,0.3,9,2.2,12.7,5.6c4.8,4.6,8.5,9.2,11.2,14c1.9,3.3,2.9,6.2,3.1,8.7c0.2,2.5-0.2,4.6-1.2,6.4
		c-1,1.8-2.8,3.8-5.3,5.8c-1.1,0.9-2.4,1.9-3.8,2.9c-1.4,1-3.1,2.1-5,3.3c-1.9,1.2-3.3,2.1-4.1,2.6c-0.2,0.2-0.6,0.5-1.2,0.8
		c-0.6,0.3-1,0.5-1.2,0.6c-1.3,0.8-2.2,1.6-2.5,2.3s-0.3,1.8,0.2,3.3c1.1,3.4,2.4,6.4,3.9,9c0.8,1.3,2,3.2,3.6,5.6
		c1.6,2.4,2.6,3.8,3,4.3l6.7,10.7c0.2,0.3,0.5,0.9,0.8,1.7c0.7,1.8,0.9,3,0.5,3.7c-0.4,0.7-1.6,1-3.4,1c-1.3,0.1-3.5,0.1-6.4,0
		c-2.1,0-3.5-0.9-4.2-2.6c-0.4-1.1-0.9-2.2-1.3-3.3s-1.1-2.2-1.9-3.6c-0.8-1.3-1.4-2.3-1.8-2.9s-1.1-1.7-2.2-3.3
		c-1.1-1.7-1.8-2.6-1.9-2.9c-2.5-3.7-4.9-7.5-7.3-11.5c-0.3-0.6-1-2.4-2.2-5.3c-0.6-1.4-1.5-2.1-2.5-1.9c-1.2,0.2-1.9,1-1.9,2.3
		c0,3.3,0.2,5.5,0.5,6.5c0.3,1.5,0.9,3.6,1.8,6.4c0.9,2.8,1.5,4.8,1.9,5.9c1.3,5.2,2.2,9,2.6,11.5c0.2,1.8,0,2.9-0.5,3.6
		c-0.6,0.6-1.8,0.9-3.8,0.9c-2.5,0.1-4.4,0.1-5.7,0c-1.1-0.1-1.9-0.3-2.3-0.7c-0.4-0.4-0.7-1.1-0.8-2.1c-0.1-1.5-0.4-3.3-0.8-5.2
		c-0.4-1.9-0.8-3.4-1-4.3c-0.3-1-0.8-2.7-1.7-5.2c-0.9-2.5-1.4-4-1.5-4.5c-1.6-4.4-2.3-8.3-2.3-11.5c0-3.6,0.9-7,2.8-10.2
		c2.1-3.5,3.8-6.2,5.1-8.1c3.7-5.6,4.4-11.6,2-18.2c-0.4-1-0.9-2.5-1.6-4.4s-1-3-1.1-3.2c-1.1-3.2-1.5-6.9-0.9-11
		c0.2-1.6,0.6-2.6,1.3-3.2c0.7-0.6,1.8-0.9,3.3-0.9c0.3,0,3.4,0,9.2,0C622.1,0.2,625.1,0.4,625.5,0.5z M635.7,36.2
		c2.8-2.4,3.8-5.4,2.9-9c-0.8-4-3.1-7.4-6.7-10.1c-3.6-2.7-7.4-3.8-11.5-3.4c-1.3,0.1-2.2,0.4-2.6,0.9c-0.4,0.5-0.3,1.4,0.1,2.6
		c0.2,0.8,0.6,2,1.2,3.6c0.6,1.6,1,2.7,1.2,3.4c1.5,4.9,1.2,9.3-0.8,13.3c-0.7,1.7-1,2.7-0.7,3.2s1.3,0.7,3,0.7c0.1,0,0.3,0,0.5,0
		h0.2C627.3,41.4,631.7,39.7,635.7,36.2z"
          />
          <path
            className="st0"
            d="M46.8,185.4c0,5.8-0.1,9.9-0.3,12c-0.1,1.5-0.5,2.6-1.2,3.2c-0.7,0.6-1.9,0.9-3.5,0.9c-2.4,0.1-4.5,0.1-6.5,0
		c-1.3,0-2.3-0.2-2.8-0.6c-0.6-0.4-1-1.2-1.3-2.5c-0.5-1.9-1.3-4-2.2-6c-1-2-1.8-3.8-2.6-5.2c-0.8-1.4-1.9-3.3-3.3-5.6
		c-1.4-2.3-2.5-4-3.1-5.2c-0.3-0.6-1-1-2-1.2c0.3,1.9,0.5,3,0.5,3.1c0.2,0.7,0.7,2.1,1.4,4.2c0.7,2.1,1.2,3.7,1.5,4.9
		c1,4.1,1.7,7.4,2.2,10c0.1,1.5-0.1,2.6-0.6,3.1c-0.5,0.5-1.5,0.8-3.1,0.9c-2.3,0.1-4.4,0.1-6.5,0c-1.1-0.1-1.9-0.3-2.3-0.7
		c-0.4-0.4-0.6-1.1-0.6-2.1c-0.1-1.5-0.4-3.2-0.8-5.1c-0.4-1.8-0.8-3.4-1.2-4.6c-0.4-1.2-0.9-2.9-1.6-5c-0.7-2.1-1.2-3.7-1.5-4.7
		c-0.9-3-1.6-6.2-2-9.5c-0.6-3.9,0.2-7.7,2.5-11.4c2.2-3.7,3.8-6.4,4.9-8c4-5.7,4.7-12,2.2-18.6c-0.4-1.2-1.3-3.7-2.6-7.5
		c-1-3.1-1.3-6.6-0.8-10.6c0.2-1.5,0.6-2.6,1.2-3.2c0.6-0.6,1.7-0.8,3.4-0.8c2.5-0.1,4.5-0.1,6,0c1.3,0,2.2,0.2,2.7,0.6
		c0.5,0.4,0.7,1.2,0.8,2.3c0.3,4.6,2.6,10.4,6.9,17.4c1,1.6,2.1,3.6,3.2,6c2.2,4.3,2.4,8.6,0.6,12.9l-2.8,7.5
		c-1.2,3.3-1.7,6.2-1.4,8.6c0.1,1.6,0.7,2.7,1.7,3.2c0.8-1.2,1.3-1.8,1.4-1.8c1.2-3.5,3.8-7.5,7.8-12l5.2-6
		c4.3-4.8,5.9-10.7,4.9-17.5c-0.1-1.1-0.3-3.4-0.6-6.8c-0.4-3.5,0.4-7.4,2.3-11.8c0.7-1.7,1.9-2.6,3.7-2.6c3.1-0.1,5.4-0.1,7.1,0
		c2.2,0.1,3.1,1.2,2.8,3.4c-0.8,4.3-0.1,9.5,2.2,15.5c0.7,1.8,1.4,4,2,6.3c1.3,4.5,0.9,8.8-1.4,12.8c-2.1,3.6-3.6,6.2-4.8,7.7
		c-4.6,6.6-5.6,13.8-3.1,21.5c0.2,0.6,0.8,2.3,1.7,4.9c0.9,2.7,1.6,4.7,2,6.2c0.9,3.3,1.6,6.4,2,9.2c0.2,1.5,0,2.6-0.5,3.3
		c-0.6,0.7-1.7,1-3.3,1c-2.5,0.1-4.6,0.1-6.5,0c-1-0.1-1.7-0.4-2.2-0.8c-0.4-0.4-0.6-1.1-0.6-2.2c-0.3-4.3-1.9-10.4-4.9-18.2
		c0-0.1-0.1-0.3-0.2-0.7c-0.2-0.4-0.3-0.6-0.3-0.6c-0.1-0.1-0.2-0.2-0.4-0.5c-0.2-0.3-0.4-0.4-0.7-0.3s-0.6,0.1-1,0
		C46.7,182.8,46.8,185.2,46.8,185.4z"
          />
          <path
            className="st0"
            d="M102.2,108.4c1.5,0,2.4,0.2,2.9,0.7c0.5,0.5,0.7,1.4,0.7,2.7c-0.2,4.6,1,9.6,3.7,15.1c1.4,3,2.5,6,3.6,8.9
		c1.3,3.6,1.1,7.2-0.5,10.8c-1.7,3.5-3.1,6.3-4.2,8.4c-3.5,6.2-3.9,12.8-1.1,19.7c0.5,1.2,1.4,3.2,2.5,5.8c1.1,2.6,2,4.5,2.5,5.8
		c0.8,2.2,2,5.5,3.4,10c0.2,0.5,0.4,1.4,0.5,2.7c0,1.7-0.7,2.6-2.2,2.7c-2.7,0.1-5.4,0.1-8.1,0c-1.4-0.1-2.2-1.1-2.7-3
		c-0.6-2.4-1.1-4.2-1.4-5.3c-0.7-2.5-2.4-3.7-5-3.7c-4.9,0-7.4,0-7.5,0c-1.3,0.1-2.1,0.4-2.5,0.8c-0.4,0.4-0.6,1.3-0.5,2.5
		c0,0.2,0,1.1,0.1,2.8c0.1,1.7,0,2.6-0.1,2.8c-0.1,1-0.4,1.8-0.8,2.3c-0.4,0.5-1.2,0.7-2.3,0.7c-1.2,0.1-3.4,0.1-6.4,0
		c-1.8,0-2.6-0.7-2.5-2.2c0.2-2.5,0.1-5.1-0.3-7.9c-0.4-2.8-1-5.7-1.8-9c-0.8-3.2-1.3-5.8-1.6-7.6c-0.9-6.4,0.4-12.3,4.1-17.5
		l6.2-8.6c4.1-5.2,5.3-11,3.7-17.5c-0.5-1.7-1.1-4.1-1.7-7.2c-0.9-4.1-0.8-8,0.3-11.7c0.4-1.6,0.9-2.6,1.6-3.1
		c0.6-0.5,1.7-0.8,3.3-0.8c0.4,0,2.7,0,6.9,0C99.6,108.4,102,108.4,102.2,108.4z M96.8,132c-0.1,1.8-0.2,2.7-0.2,2.8
		c0.4,4.4-0.6,8.4-3.1,12l-3.6,5c-3.2,4.6-4.9,7-5.1,7.3c-2.5,3.9-3.6,8.3-3.3,13.3c0.2,2.6,1.4,4,3.4,4.1c2.3,0.2,4.8,0.2,7.5,0
		c1.5-0.1,2.1-0.9,1.9-2.5c0-0.4-0.1-0.9-0.3-1.6c-2.1-5.9-1.5-11.9,1.9-17.8c1.3-2.4,2.5-5.2,3.9-8.4c1.5-3.6,1.3-7.5-0.5-11.7
		c0-0.1-0.6-1-1.7-2.7L96.8,132z"
          />
          <path
            className="st0"
            d="M143.5,107.1c4.7,0.3,9,2.2,12.7,5.6c4.7,4.5,8.4,9.2,11.1,13.9c1.9,3.3,2.9,6.2,3.1,8.7
		c0.2,2.5-0.2,4.6-1.2,6.4c-1,1.8-2.8,3.7-5.3,5.8c-1.1,0.9-2.4,1.9-3.8,2.9c-1.4,1-3,2.1-4.9,3.3c-1.9,1.2-3.3,2.1-4.1,2.6
		c-0.2,0.2-0.6,0.5-1.2,0.8c-0.6,0.3-0.9,0.5-1.2,0.6c-1.3,0.8-2.2,1.6-2.5,2.3c-0.3,0.7-0.3,1.8,0.2,3.2c1.1,3.4,2.4,6.4,3.9,9
		c0.8,1.3,2,3.2,3.6,5.6s2.6,3.8,3,4.3l6.6,10.7c0.2,0.3,0.5,0.9,0.8,1.7c0.7,1.8,0.9,3,0.5,3.6c-0.4,0.7-1.5,1-3.4,1
		c-1.3,0.1-3.4,0.1-6.3,0c-2.1,0-3.4-0.9-4.2-2.6c-0.4-1.1-0.8-2.2-1.3-3.2c-0.5-1-1.1-2.2-1.9-3.6c-0.8-1.3-1.4-2.3-1.8-2.9
		s-1.1-1.7-2.2-3.3c-1.1-1.6-1.8-2.6-1.9-2.9c-2.5-3.7-4.9-7.5-7.3-11.4c-0.3-0.6-1-2.4-2.2-5.3c-0.6-1.4-1.4-2.1-2.5-1.9
		c-1.2,0.2-1.9,1-1.9,2.3c0,3.3,0.2,5.5,0.5,6.5c0.3,1.4,0.9,3.5,1.8,6.3c0.9,2.8,1.5,4.7,1.9,5.9c1.3,5.1,2.2,9,2.6,11.4
		c0.2,1.8,0,2.9-0.5,3.5c-0.6,0.6-1.8,0.9-3.8,0.9c-2.5,0.1-4.4,0.1-5.7,0c-1.1-0.1-1.9-0.3-2.3-0.7c-0.4-0.4-0.7-1.1-0.8-2.1
		c-0.1-1.5-0.4-3.3-0.8-5.2c-0.4-1.9-0.7-3.3-1-4.3c-0.3-1-0.8-2.7-1.7-5.2c-0.9-2.5-1.4-4-1.5-4.5c-1.5-4.4-2.3-8.2-2.3-11.4
		c0-3.6,0.9-7,2.8-10.2c2.1-3.5,3.8-6.2,5.1-8c3.7-5.6,4.4-11.6,2-18.1c-0.4-1-0.9-2.5-1.5-4.4c-0.6-1.9-1-3-1.1-3.2
		c-1.1-3.2-1.4-6.8-0.9-11c0.2-1.5,0.6-2.6,1.3-3.2c0.7-0.6,1.8-0.8,3.3-0.8c0.3,0,3.3,0,9.1,0C140.1,106.8,143.1,107,143.5,107.1z
		 M153.7,142.6c2.8-2.4,3.8-5.4,2.9-9c-0.8-4-3-7.4-6.6-10c-3.6-2.7-7.4-3.8-11.4-3.4c-1.3,0.1-2.2,0.4-2.6,0.9
		c-0.4,0.5-0.3,1.4,0.1,2.6c0.2,0.8,0.6,2,1.2,3.5s0.9,2.7,1.2,3.4c1.4,4.8,1.2,9.3-0.8,13.3c-0.7,1.7-0.9,2.7-0.7,3.2
		c0.3,0.5,1.3,0.7,3,0.7c0.1,0,0.3,0,0.5,0h0.2C145.3,147.8,149.7,146.1,153.7,142.6z"
          />
          <path
            className="st0"
            d="M200.8,135.8c1.2-2.8,2.2-5.3,2.9-7.6c0.2-0.7,0.7-2.7,1.4-6c1-4.6,3.4-9.4,7.1-14.2c1.1-1.4,2.5-2.2,4.2-2.3
		c3.8-0.1,6.7-0.1,8.8,0c2.4,0.1,2.9,1.2,1.7,3.2c-0.1,0.3-0.6,1-1.4,2c-2.4,3.4-4.1,7.3-5.1,11.7c-0.6,2.8-1.1,4.8-1.4,6
		c-1.2,5.2-3.7,9.6-7.4,13.1c-5.8,5.5-7.2,12.6-4.2,21.5c0.7,2.2,2.6,5.5,5.7,9.9c1,1.4,2.5,3.4,4.6,5.9c2,2.5,3.4,4.3,4.2,5.5
		c2.4,3.3,4.4,6.4,6,9.3c1.4,2.7,0.7,4.1-2.3,4.2c-0.6,0-3,0-7.1,0c-1.9,0-3.4-1-4.6-2.9c-2.4-4-5-8-7.9-12c-0.7-1-1.9-2.6-3.6-4.6
		c-1.6-2-2.8-3.6-3.6-4.7c-0.4-0.6-2.5-3.9-6.2-9.7c-1.1-2-2.4-2.1-3.7-0.5c-2.4,2.7-3.1,5.5-2.2,8.5c0.1,0.3,0.6,1.7,1.4,4.2
		c0.8,2.5,1.4,4.4,1.9,5.7c1.4,5.1,2.3,8.9,2.6,11.3c0.3,1.8,0.2,3.1-0.4,3.7c-0.6,0.6-1.8,0.9-3.8,0.9c-1.1,0-3.2,0-6.2,0
		c-1.8-0.1-2.7-1-2.8-2.8c0-1.5-0.2-3.3-0.6-5.2c-0.4-1.9-0.8-3.4-1.2-4.6c-0.4-1.1-0.9-2.9-1.7-5.2c-0.8-2.3-1.3-3.8-1.6-4.6
		c-0.7-2.3-1.3-4.4-1.7-6.5c-1-5.5-0.3-10.3,2.3-14.5c2.2-3.7,3.9-6.5,5.1-8.3c3.8-5.6,4.5-11.6,2-18.1c-0.5-1.6-1.4-4.3-2.6-7.9
		c-1.1-3.2-1.4-6.8-0.8-11c0.2-1.3,0.6-2.3,1.2-2.9c0.6-0.6,1.6-0.8,2.9-0.8c2.3-0.1,4.3-0.1,6.2,0c1.2,0,2.1,0.2,2.5,0.7
		c0.5,0.5,0.6,1.3,0.4,2.5c-0.7,4.3,0.2,10.1,2.8,17.3c0.1,0.2,0.9,3.4,2.3,9.7c0,0.1,0.2,0.8,0.6,2.2
		C199.2,138.1,200.2,137.3,200.8,135.8z"
          />
          <path
            className="st0"
            d="M240.1,198c-1.7,0-2.7-0.2-3.2-0.7c-0.5-0.5-0.8-1.6-1-3.4c-0.6-3.2-1.6-7.4-3-12.7c-0.3-1.1-0.9-3-1.7-5.5
		c-0.8-2.5-1.4-4.4-1.7-5.8c-1.6-6.3-0.6-12.1,2.8-17.5l5-7.8c3.1-4.9,3.7-10.2,1.9-15.8l-3.1-9.1c-1.2-3.3-1.4-7.1-0.8-11.4
		c0.2-1.4,0.6-2.3,1.2-2.9s1.6-0.9,3.1-0.9c8.5-0.1,17.5-0.1,27,0c1.5,0,2.4,0.2,2.8,0.7c0.4,0.5,0.6,1.5,0.6,3
		c-0.2,2.6-0.3,4.8-0.3,6.6c0,1.3-0.2,2.1-0.5,2.5c-0.4,0.4-1.2,0.6-2.4,0.6c-0.9,0.1-3,0.1-6.2,0.1c-3.2-0.1-5.3-0.1-6.3-0.1
		c-2.2,0.1-3.5,0.5-3.9,1.1c-0.4,0.6-0.3,2,0.5,4.1c1.1,3.3,1.8,5.3,2,5.8c1.3,4.4,1.3,8.4-0.2,12.2c-0.5,1.5-0.6,2.4-0.3,2.9
		c0.3,0.5,1.2,0.7,2.8,0.7c1,0,2.7,0,4.8,0c2.2,0,3.9,0,5,0c1.2,0.1,2,0.4,2.3,0.8c0.3,0.4,0.2,1.2-0.5,2.3c-0.3,0.5-2,3.3-5,8.3
		c-0.8,1.5-2.4,2.2-4.7,2.2c-1.3,0-2.9,0-4.8,0c-2,0-3.6,0-4.8,0c-1.9,0.1-3.2,0.5-4,1.2c-0.8,0.7-1.2,2-1.2,3.8
		c0,2.4,0.3,4.6,0.8,6.7c0,0.3,1,3.8,3.1,10.5c0.8,2.6,2.7,3.9,5.6,3.9c5.9,0,10.2,0,12.7,0c1.8,0,3,0.3,3.8,0.9
		c0.7,0.6,1.2,1.7,1.6,3.5c0.4,1.7,0.7,3.2,0.8,4.5c0.1,1.9-0.1,3.1-0.6,3.7c-0.5,0.6-1.7,0.9-3.6,0.9c-0.7,0-5.2,0-13.3,0
		C245,198,240.7,198,240.1,198z"
          />
          <path
            className="st0"
            d="M320.2,102.9c1.2,0,2.1,0,2.5,0c1.5,0.3,2.3,1.2,2.3,2.8c0,2.4-0.1,5.1-0.3,8.2c-0.1,0.9-0.4,1.5-0.8,1.9
		c-0.4,0.3-1.1,0.5-2.2,0.5c-0.1,0.1-1.6,0.2-4.6,0.2c-2.9,0-4.5,0-4.6,0c-2.5,0-3.9,0.3-4.3,0.9c-0.4,0.6-0.2,2,0.6,4.2
		c1,3.3,1.8,5.9,2.3,7.7c1,3.9,0.6,7.6-1.4,11.2c-2,3.6-3.6,6.2-4.8,8c-4.8,6.8-5.9,14.5-3.1,23c2.4,6.9,3.6,10.7,3.7,11.3
		c0.8,3,1.4,6,1.7,9c0.1,1.4-0.1,2.4-0.6,3c-0.5,0.6-1.5,0.8-3.1,0.8c-2.1,0.1-4,0.1-5.9,0c-1.3,0-2.3-0.2-2.8-0.6
		c-0.5-0.4-0.8-1.2-0.8-2.5c-0.2-3.5-1.5-8.9-3.9-16.1c-1.2-3.5-2.2-6.6-2.8-9.4c-1.3-5.7-0.5-10.9,2.5-15.6c2.5-4.1,4.1-6.8,4.9-8
		c3.6-5.5,4.3-11.2,2-17.3l-2-5.7c-0.9-2.7-2.8-4-5.6-3.9c-1.6,0-4.8,0-9.4,0c-2-0.1-3.3-0.5-4-1.2c-0.7-0.7-1.1-1.9-1.1-3.8
		c0-1.5,0.2-3.2,0.5-4.9c0.2-1.3,0.6-2.3,1.2-2.8c0.6-0.5,1.5-0.8,2.9-0.8c0.6,0,3.5,0,8.7,0c1.4,0,5.6,0,12.5,0
		C311.6,102.9,318.2,102.9,320.2,102.9z"
          />
          <path
            className="st0"
            d="M341.5,103c2.1,0.2,2.9,1.2,2.6,2.9c-0.9,4.6,0,10.6,2.8,17.8l1.2,4c1.6,5,1.1,9.7-1.7,14.2
		c-2.3,3.8-3.9,6.4-4.8,7.7c-4.1,6.1-5.1,12.6-2.9,19.7c0.3,1,0.9,2.8,1.7,5.2c0.8,2.4,1.4,4.3,1.9,5.7c1.1,4.2,2,7.7,2.5,10.4
		c0.2,1.9,0,3.1-0.5,3.8c-0.6,0.7-1.8,1-3.6,1c-2.4,0.1-4.3,0.1-5.9,0c-1.2-0.1-2.1-0.4-2.5-0.8c-0.4-0.4-0.7-1.2-0.8-2.5
		c-0.1-3-1.2-7.7-3.4-14.2c-1.4-4.5-2.6-8.7-3.4-12.4c-1-4.3-0.3-8.7,2.2-13c0.1-0.3,1.3-2.4,3.6-6.2l2.9-4.6
		c2.6-4.4,3.1-9.1,1.5-13.9l-3.1-9c-1.4-4.3-1.8-8.3-1.1-11.8c0.3-1.7,0.7-2.7,1.3-3.3c0.6-0.5,1.7-0.8,3.3-0.8c0.1,0,0.9,0,2.5,0
		C340.1,103,341.3,103,341.5,103z"
          />
          <path
            className="st0"
            d="M386.7,146c2.8-4.4,4.6-7.5,5.4-9.2c1.3-2.7,1.5-5.9,0.6-9.6c-0.6-2.3-1.7-5.4-3.2-9.5
		c-1.4-4.2-1.8-8.3-1.2-12.3c0.2-1.6,0.6-2.8,1.2-3.3c0.6-0.6,1.7-0.8,3.2-0.8c2.4-0.1,4.3-0.1,5.9,0c1.2,0,2.1,0.2,2.5,0.7
		c0.5,0.5,0.6,1.3,0.4,2.4c-0.7,4.9,0.3,10.8,2.9,17.6c0.1,0.2,0.2,0.5,0.3,0.9c0.1,0.4,0.2,0.7,0.3,0.9c2.5,6.5,1.8,12.5-2,18
		c-0.2,0.2-1.8,2.7-4.8,7.6c-3.1,4.9-3.9,10.3-2.5,16.2c0.4,1.4,1.1,3.5,1.9,6.2c0.9,2.7,1.5,4.6,1.8,5.7c1.4,5.1,2.3,8.9,2.6,11.4
		c0.3,1.7,0.2,2.9-0.4,3.5c-0.6,0.6-1.8,0.9-3.8,0.9c-0.3,0-1.4,0-3.2,0c-1.8,0-2.8,0-3,0c-1.9,0-3.3-0.9-4-2.8
		c-1.2-3.3-3.9-8-8.2-14.2c-3.2-4.7-5.5-8.3-7.1-10.8c-3.7-6.2-4.7-12.1-2.9-17.9c0.1-0.3,0.2-0.9,0.3-1.8l-0.3-0.9
		c-1.2,0.7-1.8,1.1-1.8,1.2c-4.4,5.6-6.1,11.3-5.1,16.9c0.1,0.5,1.1,4.4,3.1,11.5l3.5,12.9c0.1,0.4,0.2,1,0.3,1.8
		c0,1.3-0.3,2.3-0.8,2.9c-0.5,0.6-1.4,0.9-2.8,1c-2.4,0.1-4.6,0.1-6.8,0c-1.1-0.1-1.9-0.4-2.3-0.8c-0.4-0.4-0.6-1.2-0.6-2.3
		c-0.2-3.1-1.3-7.8-3.4-14.2c-1.5-4.8-2.7-8.9-3.5-12.3c-1-4.5-0.3-9,2.3-13.5c0.2-0.4,1.2-2.1,3.1-5.1l3.1-4.9
		c2.8-4.6,3.3-9.4,1.7-14.3l-3.1-9.1c-1.4-4.2-1.7-8.2-0.9-12c0.3-1.2,0.7-2.1,1.2-2.6c0.5-0.5,1.4-0.8,2.6-0.8
		c2.2-0.1,4.4-0.1,6.8,0c1.2,0,2.1,0.2,2.6,0.6c0.5,0.4,0.9,1.1,1.1,2.2c1,4.7,4,10.5,8.9,17.3c1.3,1.7,2.9,4.1,4.6,6.9
		c2.4,3.9,3,8.3,1.8,13.2c0,0.2-0.1,0.7-0.3,1.4c-0.2,0.7-0.4,1.2-0.5,1.5c-0.1,0.3-0.2,0.9-0.2,1.7c-0.1,0.8,0,1.4,0.2,1.8
		C385.9,146.7,386.6,146.1,386.7,146z"
          />
          <path
            className="st0"
            d="M445,152.1c1,0,1.7,0.2,2.1,0.5c0.4,0.4,0.5,1.1,0.4,2.1c-0.6,4.6,0.4,11,3.1,19.3c1.1,3.2,2.2,6.8,3.3,10.9
		c0.3,1.3,0.5,2.9,0.5,4.7c-0.1,1.2-0.6,2.1-1.5,2.5c-0.9,0.4-1.9,0.3-3-0.3c-0.2-0.2-0.4-0.4-0.6-0.6c-1.1-1.2-2.7-1.4-4.8-0.5
		c-4,2-9.4,2.5-16,1.7c-3.1-0.5-5.9-1.9-8.4-4.2c-2.2-2-3.9-3.8-5.3-5.6c-3.9-5.5-6.6-12.6-7.9-21.4c-0.6-4.2,0.3-8.4,2.6-12.4
		c2.1-3.5,3.9-6.4,5.4-8.7c3.5-5.4,4.2-11.1,2-17.1c-0.8-2.3-1.6-4.5-2.3-6.7c-1.1-4.1-0.2-7.7,2.8-10.6c2.2-1.9,4.2-3.3,6.1-4.2
		c5.2-2.4,11.1-2.7,17.9-0.9c2.8,0.7,5.2,2.4,7.1,5c4.8,6.3,8,13.7,9.6,22.2c0.1,0.4,0.2,0.9,0.3,1.4c0.1,2.1-0.2,3.5-0.9,4.4
		c-0.7,0.8-2.1,1.2-4.2,1.2c-2.2,0-3.8,0-4.8,0c-1.2,0-2.1-0.2-2.5-0.6c-0.4-0.4-0.6-1.2-0.5-2.3c0.2-3.6-0.9-8.3-3.3-14
		c-2.1-5-5.8-6.9-11.2-5.9c-2.6,0.5-3.7,2.1-3.3,4.7c0,0.2,0.4,1.4,1.1,3.6c1,3.3,1.6,5.1,1.6,5.3c1.2,4.4,0.8,8.5-1.4,12.3
		c-2.1,3.7-3.7,6.5-5,8.2c-4.4,6.4-5.5,13.6-3.1,21.4c1.1,3.3,1.9,5.5,2.3,6.7c1.9,4.6,5.2,6.6,10.1,6.2c3.6-0.3,5-2.2,4.2-5.6
		c-0.1-0.6-0.4-1.5-0.8-2.7c-0.4-1.2-0.7-2-0.8-2.6c-0.6-2.1-1.2-4.1-1.7-6.2c-0.4-2.1-1.6-3.3-3.6-3.6c-1.3-0.2-2.2-0.5-2.6-0.9
		c-0.4-0.4-0.6-1.2-0.5-2.3c0.1-2.6,1.3-4,3.6-4.2c0.3,0,2.7,0,7.3,0V152C442.5,152,444.7,152,445,152.1z"
          />
        </g>
      </svg>
    </Container>
  )
}

export default Tagline
