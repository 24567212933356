import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import * as Yup from "yup"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Label from "../../elements/label"
import underline from "../../../images/textures/underline_one.png"

const Container = styled.div`
  color: black;
  display: grid;
  padding-right: 15px;
  margin-top: 3rem;
  @media (min-width: 768px) {
    max-width: 330px;
  }
`

const FormContainer = styled.form`
  position: relative;
  display: grid;
  grid-template: auto / 1fr auto;
  align-items: center;
  margin-bottom: 50px;
  padding-bottom: 10px;
  background-image: url(${underline});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom left;
`

const Field = styled.div`
  input {
    border: none;
    font-family: "AvenirLTStdBlack";
    width: 100%;
    font-size: 0.85rem;
    text-transform: uppercase;
    padding: 0.5rem 0;
    transition: all 250ms ease-out;
    background-color: transparent;
    color: var(--white);
    &::-webkit-input-placeholder {
      color: var(--silver-chalice);
    }
    &::-moz-placeholder {
      color: var(--silver-chalice);
    }
    &:-ms-input-placeholder {
      color: var(--silver-chalice);
    }
    &:-moz-placeholder {
      color: var(--silver-chalice);
    }
    &:focus {
      outline: none;
    }
  }
  .feedback {
    font-family: "AvenirLTStdBlack";
    position: absolute;
    bottom: -5px;
    left: 0;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: rgba(246, 86, 100, 0.8);
    transform: translateY(100%);
  }
`

const Button = styled.div`
  button {
    width: 30px;
    height: 30px;
    font-family: "Galano Grotesque";
    font-size: 1.5rem;
    color: var(--carnation);
    background-color: transparent;
    border: none;
    transition: 250ms ease-out;
    &:focus {
      outline: none;
    }
    &:hover {
      transform: translateX(5px);
    }
  }
`

const SubmitMessageContainer = styled.div`
  color: green;
  display: grid;
  grid-template: auto / 1fr;
`

const SubmitMessage = styled.p`
  grid-area: 1 / 1 / 2 / 2;
  opacity: ${props => (props.active ? `1` : `0`)};
  transition: 250ms ease-out;
`

const Subscribe = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitFailure, setSubmitFailure] = useState(false)

  return (
    <Container>
      <Label
        fontSize={0.8}
        fontScaled
        outerRotate={0}
        innerRotate={-1}
        value="Notify me when dates are announced"
        style={{ marginBottom: "1rem", textAlign: "center" }}
      />
      <Formik
        initialValues={{ email: "" }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const result = await addToMailchimp(values.email)
            console.log("Subscribe submitt: ", result)
            setSubmitSuccess(true)
          } catch (err) {
            setSubmitFailure(true)
          }
          setSubmitting(false)
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required("Required"),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props
          return (
            <FormContainer onSubmit={handleSubmit}>
              <Field>
                <input
                  id="subscribeEmail"
                  placeholder="Email address"
                  type="email"
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? "error mouse-link"
                      : "mouse-link"
                  }
                />
                {errors.email && touched.email && (
                  <div className="feedback">{errors.email}</div>
                )}
              </Field>

              <Button>
                <button
                  type="submit"
                  disabled={isSubmitting || submitSuccess}
                  className="mouse-link"
                >
                  &#8594;
                </button>
              </Button>
            </FormContainer>
          )
        }}
      </Formik>
      <SubmitMessageContainer>
        <SubmitMessage active={submitSuccess}>
          Thank you for subscribing.
        </SubmitMessage>
        <SubmitMessage active={submitFailure}>
          Somethings went wrong, please try again.
        </SubmitMessage>
      </SubmitMessageContainer>
    </Container>
  )
}

Subscribe.propTypes = {
  email: PropTypes.string,
}

export default Subscribe
