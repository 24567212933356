import React from "react"
import { Link } from "react-scroll"
import styled from "styled-components"

import Label from "../../elements/label"
import Subscribe from "./Subscribe"
import Wheel from "./wheel"

import media from "../../../styles/media"

import icon_coffee from "./images/coffee.svg"
import icon_lunch from "./images/lunch.svg"
import icon_notebook from "./images/notebook.svg"
import icon_seating from "./images/seating.svg"
import icon_drinks from "./images/drinks.svg"

import texture from "./images/texture.png"

const Container = styled.div`
  background-color: rgba(63, 63, 63, 0.99);
  background-image: url(${texture});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: bottom right;
  position: relative;
  padding: 15vh 0 15vh var(--gutter-s);
  ${media.tablet`
    padding: 25vh 0 25vh var(--gutter-xl);
  `}
`

const Ticket = styled.div``

const Price = styled.div`
  display: inline-block;
  font-family: "Galano Grotesque";
  font-weight: 900;
  font-style: normal;
  font-size: 3rem;
  color: var(--carnation);
  position: relative;
  line-height: 0.85;
  margin-bottom: 1rem;
  &.vat {
    &:after {
      content: "excl. VAT";
      position: absolute;
      right: -25%;
      bottom: 0%;
      background-color: rgba(0, 0, 0, 0.8);
      color: rgba(255, 255, 255, 0.8);
      font-size: 0.5rem;
      padding: 0.25rem 0.5rem;
    }
  }
  ${media.tablet`
    margin-bottom: 0rem;
    font-size: 6rem;
    &.vat {
      &:after {
        font-size: 0.75rem;
        right: -15%;
        bottom: 20%;
      }
    }
  `}
`

const PriceDescription = styled.div`
  margin: 1rem 0 3rem;
  p {
    font-family: "AvenirLTStdBlack";
    a {
      font-family: "Galano Grotesque";
    }
  }
`

const InclusionList = styled.ul`
  display: flex;
  position: relative;
`

const AddOn = styled.li`
  list-style: none;
  padding: 1rem 2rem 1rem 0;
  text-align: center;
  img {
    display: inline-block;
    height: 30px;
    width: auto;
    margin-bottom: 0.5rem;
  }
  p {
    text-align: left;
    font-family: "AvenirLTStdBlack";
    text-transform: uppercase;
    font-size: 0.65rem;
    letter-spacing: 0.5px;
  }
  &:last-child {
    padding: 1rem 0 1rem 0;
  }
  ${media.tablet`
    p {
      font-size: 0.65rem;
    }
  `}
`

const WheelContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  overflow: hidden;
  pointer-events: none;
  opacity: 0.1;
  svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
  }
  ${media.tablet`
    width: 50%;
    opacity: 0.7;
  `}
`

const Tickets = () => {
  return (
    <Container id="tickets" name="tickets">
      <WheelContainer>
        <Wheel />
      </WheelContainer>
      <Ticket>
        <Label
          fontSize={0.8}
          fontScaled
          outerRotate={0}
          innerRotate={-1}
          value="Tickets starting from"
          style={{ marginBottom: "0.25rem" }}
        />
        <br />
        <Price>R5 808 -</Price>
        <br />
        <Price className="vat">R7 260</Price>
        <PriceDescription>
          <p>
            To get a discount on your ticket get in touch with one of our <br />
            Growth Hacker Partners {" "}
            <Link
              activeClass="active"
              to="partners"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="mouse-link"
            >
              &#8595;
            </Link>
          </p>
        </PriceDescription>
        <div>
          <Label
            fontSize={0.8}
            fontScaled
            outerRotate={0}
            innerRotate={-1}
            value="5-hours growth hacking marketing masterclass"
            style={{ marginBottom: "0.25rem" }}
          />
          <br />
          <Label
            fontSize={0.8}
            fontScaled
            outerRotate={-1}
            innerRotate={0}
            value="Hosted by Ryan Holiday, including"
            style={{ marginBottom: "1rem" }}
          />
          <br />
          <Label
            fontSize={0.8}
            fontScaled
            invert
            outerRotate={0}
            innerRotate={-1}
            value="A 1-hour conversation between Ryan and Black Coffee"
          />
          <br />
          <Label
            fontSize={0.8}
            fontScaled
            invert
            outerRotate={0}
            innerRotate={0}
            value="on conquering the international market"
            style={{ marginBottom: "1rem" }}
          />
          <br />
          <Label
            fontSize={0.8}
            fontScaled
            outerRotate={0}
            innerRotate={-1}
            value="followed by a networking afterparty"
            style={{ marginBottom: "3rem" }}
          />
          <br />
          <Label
            fontSize={0.8}
            fontScaled
            outerRotate={0}
            innerRotate={-2}
            style={{ marginBottom: "2rem" }}
            value="your ticket includes"
          />
        </div>
        <InclusionList>
          <AddOn>
            <img
              src={icon_coffee}
              alt="Sound Idea Sessions | Tickets"
              loading="lazy"
            />
            <p>Tea + Coffee</p>
          </AddOn>
          <AddOn>
            <img
              src={icon_lunch}
              alt="Sound Idea Sessions | Tickets"
              loading="lazy"
            />
            <p>Lunch</p>
          </AddOn>
          <AddOn>
            <img
              src={icon_notebook}
              alt="Sound Idea Sessions | Tickets"
              loading="lazy"
            />
            <p>Notebook + Pen</p>
          </AddOn>
        </InclusionList>
        <InclusionList>
          <AddOn>
            <img
              src={icon_seating}
              alt="Sound Idea Sessions | Tickets"
              loading="lazy"
            />
            <p>Cinema-styled seating</p>
          </AddOn>
          <AddOn>
            <img
              src={icon_drinks}
              alt="Sound Idea Sessions | Tickets"
              loading="lazy"
            />
            <p>Afterparty drink</p>
          </AddOn>
        </InclusionList>
        <Subscribe />
      </Ticket>
    </Container>
  )
}

export default Tickets
