import React from "react"

import Home from "./Home"
import Speakers from "./Speakers"
import Venue from "./Venue"
import Tickets from "./Tickets"
import Sponsors from "../Sponsors"

const IndexPage = () => {
  return (
    <>
      <Home />
      <Speakers />
      <Venue />
      <Tickets />
      <Sponsors />
    </>
  )
}

export default IndexPage
