import React from "react"
import styled from "styled-components"

import RyanHoliday from "./ryanHoliday"
import BlackCoffee from "./blackCoffee"

const Container = styled.div`
  background-color: rgba(0, 93, 107, 0.99);
`

const Speakers = () => {
  return (
    <Container name="speakers">
      <RyanHoliday />
      <BlackCoffee />
    </Container>
  )
}

export default Speakers
