import React from "react"
import styled from "styled-components"

import Header from "./Header"
import Tagline from "./Tagline"

import dirt from "./dirt.png"

import media from "../../../../styles/media"

const Container = styled.div`
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${media.tablet`
    min-height: 100vh;
  `}
`

const HeaderExtended = styled(Header)`
  ${media.tablet`
    position: absolute;
    left: auto;
    right: 10px;
    bottom: 20px;
    top: auto;
  `}
`

const TaglineExtended = styled(Tagline)`
  margin-top: 2rem;
  ${media.tablet`
    margin-top: 0;
    transform: translateY(60px);
  `}
`

const Dirt = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: auto;
  pointer-events: none;
  transform: translate(0, 80px);
`

const Landing = () => {
  return (
    <Container>
      <TaglineExtended />
      <HeaderExtended />
      <Dirt src={dirt} alt="Sound Idea Sessions" />
    </Container>
  )
}

export default Landing
